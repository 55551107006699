import axios from 'axios';
import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const ForgottenPassword = () => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsSaving(true);
    const { email } = values;
    axios
      .post(`${process.env.REACT_APP_API_URL}/forgotten-password`, {
        email,
      })
      .then(() => {
        setIsSaving(false);
      })
      .catch((error) => {
        setIsSaving(false);
        message.error('An error occurred');
        console.error(error);
      });
  };

  return (
    <div className="fullscreen-form">
      <Form form={form} onFinish={onFinish} size="large" layout="vertical" requiredMark={false}>
        <Form.Item label={t('EMAIL')} name="email" rules={[{ required: true }]}>
          <Input prefix="" placeholder={t('EMAIL')} />
        </Form.Item>
        <Button loading={isSaving} size="large" type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: "#3493EB", borderColor: "#3493EB", color: "#FFF" }}>
          {t('FORGOT_PASSWORD')}
        </Button>
      </Form>
    </div>
  );
};

export default ForgottenPassword;
