import React, { useState } from 'react';
import { Button, Modal, Form, Input, message } from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMainData } from '../providers/MainDataProvider';

const LoginModal = ({ visible, onCancel }) => {
  const history = useHistory();
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isMFAValidationVisible, setIsMFAValidationVisible] = useState(false);
  const { setToken } = useMainData()

  const onFinish = (values) => {
    setIsLoading(true);
    const { code } = values;

    axios
      .post(`${process.env.REACT_APP_API_URL}/mfa/validate`, {
        email,
        code,
      })
      .then(({ data }) => {
        setIsLoading(false);
        localStorage.setItem('email', email);

        const { expiration, accessToken } = data;
        const expirationDate = new Date(expiration)
        setToken(accessToken, expirationDate)
        history.push('/app/upcoming');
      })
      .catch((error) => {
        setIsLoading(false);
        message.error('An error occurred');
        console.error(error);
      });
  };

  const onLogin = () => {
    setIsLoading(true);
    const { email, password } = form.getFieldsValue();

    setEmail(email)
    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, {
        email,
        password,
      })
      .then(() => {
        setIsLoading(false);
        setIsMFAValidationVisible(true)
      })
      .catch((error) => {
        setIsLoading(false);
        message.error('An error occurred');
        console.error(error);
      });
  }

  return (
    <Modal
      title={null}
      forceRender={true}
      maskClosable={true}
      destroyOnClose={true}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <h1>{t('LOGIN')}</h1>
      <Form form={form} onFinish={onFinish} size="large" layout="vertical" requiredMark={false}>
        {isMFAValidationVisible ? 
          <MFAForm {...{ isLoading, t }} /> :
          <LoginForm  {...{ isLoading, t, onLogin }} />
        }
      </Form>
    </Modal>
  );
};

const LoginForm = ({ isLoading, t, onLogin }) => {

  return (<>
        <Form.Item label={t('EMAIL')} name="email" rules={[{ required: true }]}>
          <Input prefix="" placeholder={t('EMAIL')} />
        </Form.Item>
        <Form.Item label={t('PASSWORD')} name="password" rules={[{ required: true }]}>
          <Input type="password" prefix="" placeholder={t('PASSWORD')} />
        </Form.Item>
        <Button onClick={onLogin} loading={isLoading} size="large" type="primary" style={{ width: '100%', backgroundColor: "#3493EB", borderColor: "#3493EB", color: "#FFF" }}>
          {t('LOGIN')}
        </Button>
        <Button size="small" type="link" style={{ width: '100%', marginTop: 10 }}>
          <Link to={'/forgotten-password'}>{t('FORGOTTEN_PASSWORD')}</Link>
        </Button>
  </>)
}

const MFAForm = ({ isLoading, t }) => {
  return (<>
        <Form.Item label={t('CODE')} name="code" rules={[{ required: true }]}>
          <Input prefix="" placeholder={t('CODE')} />
        </Form.Item>
        <Button loading={isLoading} size="large" type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: "#3493EB", borderColor: "#3493EB", color: "#FFF" }}>
          {t('OK')}
        </Button>
  </>)
}

LoginModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default styled(LoginModal)`
  .container {
    h1 {
      text-align: center;
      padding: 20px 0px 0px;
    }
  }
`;
