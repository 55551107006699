export function minutesToHoursAndOrMinutes(_minutes) {
  let result = '';
  const minutesAbs = Math.abs(_minutes);
  const hours = Math.trunc(minutesAbs / 60);
  const minutes = Math.round(minutesAbs % 60);
  if (_minutes < 0) {
    result += '-';
  }
  if (hours) {
    result += `${hours}h`;
  }
  if (minutes) {
    result += `${hours ? minutes.toString().padStart(2, '0') : minutes}${hours ? '' : 'm'}`;
  }
  return result;
}
