import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import BasicPage from '../components/BasicPage';

const Landing = ({ className }) => {
  // const history = useHistory();
  const { t } = useTranslation(undefined, { useSuspense: true });

  // useEffect(() => {
  //   if (Cookies.get('auth')) {
  //     history.push('/app');
  //   }
  // }, []);

  return (
    <BasicPage>
      <div className={className}>
        <div style={{width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
          <div className="container">
            <h2>{t('LANDING.TITLE')}</h2>
            <p>{t('LANDING.DESCRIPTION')}</p>
          </div>

          <div className="video-responsive" style={{ display: "flex", flexGrow: 1, justifyContent: "center"}}>
            <iframe 
              width="270" 
              height="480" 
              src="https://www.youtube.com/embed/-pWu18nl4MI" 
              title="YouTube video player" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </BasicPage>
  );
};

export default styled(Landing)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;

  .logo {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 45px;
    opacity: 0.66;
    transition: opacity 0.3s ease-in;

    &:hover {
      opacity: 1;
    }
  }

  .login {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .container {
    max-width: 600px;
    padding-top: 60px;
    h1 {
      font-size: 3em;
      line-height: 1em;
    }

    p {
      font-size: 1.3em;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 680px) {
    padding: 25px;
    .container {
      h1 {
        font-size: 3em;
        line-height: 1.1em;
      }

      p {
        font-size: 1.1em;
      }
    }
  }
`;
