import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Error404 = () => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  return (
    <Result
      status="404"
      title="404"
      subTitle={t('404_MSG')}
      extra={
        <Link to="/app">
          <Button type="primary" style={{backgroundColor: "#3493EB", borderColor: "#3493EB", color: "#FFF"}}>{t('BACK_HOME')}</Button>
        </Link>
      }
    />
  );
};

export default Error404;
