import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Spin } from 'antd';
import axios from 'axios';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import i18n from './i18n';

import frFR from 'antd/es/locale/fr_FR';
import nlNL from 'antd/es/locale/nl_NL';
import enUS from 'antd/es/locale/en_US';

const languages = {
  fr: frFR,
  nl: nlNL,
  en: enUS
};

axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
    // if (error.response?.status === 401) {
    //   localStorage.removeItem('email');
    //   window.location.href = '/';
    //   return Promise.reject(error);
    // } else {
    //   return Promise.reject(error);
    // }
  },
);

const Root = () => {
  const {
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: true });
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <ConfigProvider 
          locale={languages[language]}
          theme={{
            primaryColor: '#3493EB',
          }}
        >
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
};

ReactDOM.render(
  // <React.StrictMode>
  <Suspense fallback={<Spin spinning={true} style={{ width: '100%', height: '100vh' }} />}>
    <Root />
  </Suspense>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
