import React, { useEffect } from 'react';

export const PRIVACY_LINK = "https://pages.flycricket.io/comagenda/privacy.html"

export const Privacy = () => {

  useEffect(() => {
    window.location.replace(PRIVACY_LINK);
  }, []);

  return <></>
};

