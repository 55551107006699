import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { MailOutlined } from '@ant-design/icons';
import BasicPage from '../components/BasicPage';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { Box, Pagination, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PAGE_SIZE = 8;

const Stores = () => {
  const { t } = useTranslation(undefined, { useSuspense: true });

  const [page, setPage] = useState(1)
  const [pageData, setPageData] = useState({})

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/shop/shops?page=${page}&size=${PAGE_SIZE}`)
      .then(({ data }) => {
        setPageData(data)
      })
      // .finally(() => {
      //   setIsLoading(false)
      // })
  }, [page])

  const handleChangePage = (event, value) => {
    setPage(Math.max(value, 1));
  };

  const trunc = (value, length) => value?.length > length ? value.slice(0, length) + " ..." : value

  return (
  <>
    <BasicPage>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginLeft: 50, marginRight: 50, }}>
        <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
          {pageData?.data?.map((store, idx) => {
              return (
                <div key={idx} style={{ marginLeft: 15, marginRight: 15, maxWidth: 300 }}>
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 30, padding: 10, border: "2px solid #3493EB", borderRadius: 10, marginLeft: 10, marginRight: 10, flexGrow: 1, flexShrink: 1, height: 345 }}>
                    <h3 style={{ fontWeight: "bold", textAlign: "center" }}>{store?.name}</h3>
                    <p>{trunc(store?.description, 180)}</p>
                    <a href={`mailto:${store?.email}`} style={{ color: "#3493EB", cursor: "pointer", marginBottom: 10 }}>{store?.email} <MailOutlined style={{ marginLeft: 15 }} /></a>
                    {store?.phone && <p>{store.phone}</p>}
                    {store?.address && <p>{trunc(store.address, 100)}</p>}
                    <Button size="small" type="link" style={{ marginTop: 15, alignSelf: "center", width: 100, padding: "0 15px", color: "#3493EB", border: "1px solid #3493EB" }}>
                      <Link to={`/shops/${store?.urlSlug}`}>{t('VIEW')}</Link>
                    </Button>
                  </div>
                </div>
              );
            })
          }
        </div>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 5, marginBottom: 5 }}>
          <Typography>{t('PAGE')}: {page}</Typography>
          <Pagination page={page} onChange={handleChangePage} hidePrevButton={page <= 1} hideNextButton={!pageData?.hasMore} count={pageData?.hasMore ? page + 1 : page} siblingCount={0} boundaryCount={0}  />
        </Box>
      </div>
    </BasicPage>
  </>
)};

export default styled(Stores)`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 80px;

  @media (max-width: 680px) {
    padding: 0 25px;
    .container {
      h1 {
        font-size: 3em;
        line-height: 1.1em;
      }

      p {
        font-size: 1.1em;
      }
    }

    .wrapOnMobile { flex-wrap: wrap }
  }

  @media (max-width: 1000px) {
    .wrapOnSmallScreen { flex-wrap: wrap }
  }
`;