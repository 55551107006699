import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Divider, message } from 'antd';
import { useHistory } from 'react-router';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'
import LoginModal from './LoginModal';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MuiButton from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useMainData } from '../providers/MainDataProvider';


const Header = ({ isConnected, className }) => {
  const { t } = useTranslation(undefined, { useSuspense: true }); 
  const { myShop } = useMainData()

  const history = useHistory();
  const [email] = useState(localStorage.getItem('email'));
  const [isLoading, setIsLoading] = useState(false);
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [pages, setPages] = useState([])
  
  const [anchorElNav, setAnchorElNav] = useState(null);

  useEffect(() => {
    const pages = [{ name: t('SHOPS'), path: "/shops" }]

    if (isConnected) {
      pages.push({ name: t('APPOINTMENTS'), path: "/app/upcoming" })
      if (myShop) pages.push( { name: t('MY_SHOP'), path: `/shops/${myShop?.urlSlug}` })
    }

    setPages(pages)
  }, [isConnected, myShop])

  const onLogout = () => {
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/logout`)
      .then(() => {
        setIsLoading(false);
        localStorage.removeItem('email');
        Cookies.remove('auth');
        history.push('/');
        location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        message.error('An error occurred');
        console.error(error);
      });
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <AppBar className={className}  position="static" style={{ backgroundColor: "#0b0c0f" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* DESKTOP MENU */}
            <Link to="/" >
              <img style={{ height: 35 }} src={`${process.env.PUBLIC_URL}/comagenda-logo.svg`} alt="ComAgenda" />
            </Link>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page, idx) => (
                  <MenuItem key={idx} onClick={handleCloseNavMenu}>
                    <Link to={page.path}><Typography textAlign="center" color="white">{page.name}</Typography></Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* MOBILE MENU */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page, idx) => (
                <MuiButton
                  key={idx}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  <Link to={page.path}><Typography textAlign="center" color="white">{page.name}</Typography></Link>
                </MuiButton>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {isConnected ? 
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className='hideOnMobile'>
                  {email}
                  <Divider type="vertical" style={{ marginLeft: 20 }} />
                </div>
                <Button loading={isLoading} danger type="text" onClick={onLogout}>
                  {t('LOGOUT')}
                </Button>
              </div> : 
              <Button className="login" style={{ padding: 0, height: 35, width: 120, backgroundColor: "#3493EB", borderColor: "#3493EB", color: "#FFF" }} size="large" type="primary" onClick={() => {console.log("setShowModalLogin(true)"); setShowModalLogin(true)}}>
                {t('LOGIN')}
              </Button>}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <LoginModal visible={showModalLogin} onCancel={() => setShowModalLogin(false)} />
    </>
  );
};

export default styled(Header)`
  @media (max-width: 680px) {
    .hideOnMobile { display: none }
  }
  
`;
