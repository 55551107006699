import React, { useState } from 'react';
import styled from 'styled-components';
import colors from '../styles/colors';
import { minutesToHoursAndOrMinutes } from '../utils';
import dayjs from 'dayjs';
import { Table } from 'antd';
import { HourglassOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const AppointmentDetails = ({ appointment, className }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [columns] = useState([
    {
      dataIndex: 'email',
      key: 'email',
    },
    {
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        switch (text) {
          case 'CONFIRMED':
            return <CheckCircleTwoTone twoToneColor="#52c41a" />;
          case 'CANCELED':
            return <CloseCircleTwoTone twoToneColor="#c41a1a" />;
          default:
            return <HourglassOutlined />;
        }
      },
    },
  ]);

  return (
    <div className={className}>
      {!!appointment?.description && <p>{appointment.description}</p>}
      <h4>{t('ORGANIZER')}</h4>
      {appointment?.organizer}
      <h4>{t('DATE_AND_TIME')}</h4>
      {dayjs(appointment.start).format('LLL')}
      <h4>{t('DURATION')}</h4>
      {minutesToHoursAndOrMinutes(dayjs(appointment.end).diff(appointment.start, 'minute'))}
      <h4>{t('ATTENDEES')}</h4>
      <Table
        size="small"
        showHeader={false}
        dataSource={appointment?.attendees}
        columns={columns}
        pagination={false}
        rowKey="email"
      />
    </div>
  );
};

export default styled(AppointmentDetails)`
  h4 {
    color: ${colors.gray};
    margin: 10px 0 0;
  }
`;

AppointmentDetails.propTypes = {
  appointment: PropTypes.object,
};
