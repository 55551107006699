import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import colors from '../styles/colors';
import { useTranslation } from 'react-i18next';

const AppointmentCard = ({ className, appointment: { start, end, summary, organizer, attendees }, onClick }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [startDate] = useState(dayjs(start));
  const [endDate] = useState(dayjs(end));

  return (
    <div className={className} onClick={onClick}>
      <div className="left">
        <span className="month">{startDate.format('MMMM')}</span>
        <span className="date">{startDate.format('DD')}</span>
        <span className="day">{startDate.format('ddd').toLocaleUpperCase()}</span>
      </div>
      <div className="divider"></div>
      <div className="right">
        <span className="hours">
          {startDate.format('HH:mm')}
          {' - '}
          {endDate.format('HH:mm')}
        </span>
        <h3>{summary}</h3>
        <span className="organizer">
          {t('WITH')} {[organizer, ...attendees.map((x) => x.email)].join(', ')}
        </span>
      </div>
    </div>
  );
};

export default styled(AppointmentCard)`
  background-color: #1e2e39;
  max-width: 450px;
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in;
  margin-bottom: 10px;

  &:hover {
    transform: scale(1.02);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 12px 36px;
    z-index: 2;
  }

  .left {
    display: flex;
    flex-direction: column;
    width: 80px;
    align-items: center;
    justify-content: center;
    color: gray;

    .date {
      color: ${colors.blue};
      font-weight: bold;
      font-size: 36px;
      line-height: 36px;
    }
  }

  .divider {
    position: relative;
    width: 1px;
    height: 60px;
    background-color: lightgray;
    margin-left: 5px;
    margin-right: 20px;
  }

  .right {
    text-align: left;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    h3 {
      font-weight: bold;
    }
    .organizer {
      color: gray;
      font-style: italic;
      white-space: nowrap;
    }
  }
`;

AppointmentCard.propTypes = {
  appointment: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};
