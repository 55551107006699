import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router';
import UpcomingAppointments from './UpcomingAppointments';
import Error404 from '../../components/Error404';
import BasicPage from '../../components/BasicPage';

const Private = () => {
  const { path, url } = useRouteMatch();
  return (
    <BasicPage>
      <Switch>
        <Route exact path={`${path}`} render={() => <Redirect to={`${url}/upcoming`} />} />
        <Route exact path={`${path}/upcoming`}>
          <UpcomingAppointments />
        </Route>
        <Route component={() => <Error404 />} />
      </Switch>
    </BasicPage>
  );
};

export default Private