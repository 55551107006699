import React, { useState } from 'react';
import { Button, message } from 'antd';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const CancelAppointment = ({ appointment, style, onCancelAppointment }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    const { _id } = appointment;
    setLoading(true);
    axios
      .patch(`${process.env.REACT_APP_API_URL}/appointments/${_id}/cancel`)
      .then(() => {
        setLoading(false);
        onCancelAppointment?.(_id);
      })
      .catch((error) => {
        setLoading(false);
        message.error('An error occurred');
        console.error(error);
      });
  };

  return (
    <React.Fragment>
      {appointment.organizer !== localStorage.getItem('email') && (
        <Button loading={loading} style={style} danger onClick={onClick}>
          {t('CANCEL_APPOINTMENT')}
        </Button>
      )}
    </React.Fragment>
  );
};

export default CancelAppointment;

CancelAppointment.propTypes = {
  appointment: PropTypes.object.isRequired,
  onCancelAppointment: PropTypes.func,
};
