import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    const token = Cookies.get("auth");

    if (token) {
      axios
      .get(`${process.env.REACT_APP_API_URL}/whoami`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsAuthenticated(true)
      })
      .catch(() => {
        Cookies.remove('auth')
      })
      .finally(() => {
        setIsLoading(false)
      })
    }
    else {
      setIsLoading(false)
    }
  }, [])

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoading ? (
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )) : <></>
      }
    />
  );
};

export default PrivateRoute;
