import React from 'react';
import styled from 'styled-components';
import { PRIVACY_LINK } from '../pages/Privacy';
import { useTranslation } from 'react-i18next';

const Footer = ({ className }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });

  return (
    <>
      <footer className={className}>
        <a href="/support" style={{ padding: "0 15px", color: "#3493EB", cursor: "pointer", marginBottom: 10 }}>{t('Support')}</a>
        <a href={PRIVACY_LINK} target={"_blank"} style={{ padding: "0 15px", color: "#3493EB", cursor: "pointer", marginBottom: 10 }}>{t('Privacy_Policy')}</a>
      </footer>
    </>
  );
};

export default styled(Footer)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #0b0c0f;
  height: 60px;
  color: gray;
  width: 100vw;
  padding: 0 10px;
  z-index: 2;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
