import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Card, message, Spin, Result } from 'antd';
import dayjs from 'dayjs';
import { CalendarAdd24Regular } from '@fluentui/react-icons';
import AppointmentDetails from '../components/AppointmentDetails';
import AddToMyCalendar from '../components/AddToMyCalendar';
import { useTranslation } from 'react-i18next';

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const Approve = ({ className }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const { token } = useParams();
  const [appointment, setAppointment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    setAppointment(null);
    setIsLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/requests/approve/${token}`,
        {},
        {
          cancelToken: cancelTokenSource.token,
        },
      )
      .then(({ data }) => {
        setAppointment(data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          message.error('An error occurred');
        }
        if (mounted) {
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [token]);

  return (
    <div className={className}>
      <Spin spinning={isLoading}>
        {!!appointment && (
          <React.Fragment>
            <Result icon={<CalendarAdd24Regular />} title={t('APPOINTMENT_CONFIRMED_MSG')} />
            <Card title={appointment?.summary} bordered={false}>
              <AppointmentDetails appointment={appointment} />
              <AddToMyCalendar appointment={appointment} style={{ marginTop: 20, width: '100%', backgroundColor: "#3493EB", borderColor: "#3493EB", color: "#FFF" }} />
            </Card>
          </React.Fragment>
        )}
      </Spin>
    </div>
  );
};

export default styled(Approve)`
  .ant-card {
    width: 450px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin: 25px auto;
  }
  .ant-result {
    margin: 20px;
    border-radius: 8px;

    .ant-result-title {
      font-weight: bold;
      line-height: 1.5em;
    }

    .ant-result-icon {
      margin-bottom: 10px;
      svg {
        width: 64px;
        height: 64px;
        color: #72be85;
      }
    }
  }
`;
