import Header from "./Header"
import styled from 'styled-components';
import Footer from "./Footer";
import { useMainData } from "../providers/MainDataProvider";

const BasicPage = ({ className, children }) => {
  const { isConnected } = useMainData()

  return (
    <>
      <div className={className}>
        <Header isConnected={isConnected} />
        {children}
      </div>
      <Footer />
    </>
  )
}

export default styled(BasicPage)`
  min-height: 100vh;
`;