import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Spin, Empty } from 'antd';
import AppointmentCard from '../../components/AppointmentCard';
import { motion } from 'framer-motion';
import AppointmentModal from '../../components/AppointmentModal';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const container = {
  show: {
    transition: {
      staggerChildren: 0.15,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: 50 },
  show: { opacity: 1, y: 0 },
};

const UpcomingAppointments = ({ className }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const [isLoading, setIsLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  useEffect(() => {
    const token = Cookies.get('auth')
    let mounted = true;
    setAppointments([]);
    setIsLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .get(`${process.env.REACT_APP_API_URL}/appointments/next`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        setAppointments(data.docs);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.log(error);
        }
        if (mounted) {
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, []);

  const onCancelAppointment = (_id) => {
    const index = appointments.findIndex((x) => x._id === _id);
    if (~index) {
      const updatedAppointment = [...appointments];
      updatedAppointment.splice(index, 1);
      setAppointments(updatedAppointment);
      setSelectedAppointment(null);
    }
  };

  return (
    <div className={className}>
      <h1>{t('UPCOMING_APPOINTMENTS')}</h1>
      {isLoading && <Spin size="large" />}
      {appointments?.length ? (
        <motion.div variants={container} initial="hidden" animate="show" style={{ width: '100%' }}>
          {appointments?.map((appointment, index) => (
            <motion.div
              key={index}
              variants={item}
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
            >
              <AppointmentCard appointment={appointment} onClick={() => setSelectedAppointment(appointment)} />
            </motion.div>
          ))}
        </motion.div>
      ) : (
        <Empty />
      )}
      <AppointmentModal
        appointment={selectedAppointment}
        onCancel={() => setSelectedAppointment(null)}
        onCancelAppointment={onCancelAppointment}
      />
    </div>
  );
};

export default styled(UpcomingAppointments)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 10px;
`;
