import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from "dayjs"
import { colors } from '../constants/colors';
import { MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import BasicPage from '../components/BasicPage';

const TIME_SLOT_DURATION = 30; // in minutes

const Store = ({ className }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  let { storeSlug } = useParams();

  const [isLoading, setIsLoading] = useState(true)
  const [store, setStore] = useState()
  const [selectedDate, setSelectedDate] = useState(dayjs('2023-05-22'));
  const [unavailabilities, setUnavailabilities] = useState()
  const [start, setStart] = useState()
  const [end, setEnd] = useState()

  useEffect(() => {
    const date = new Date(selectedDate)
    
    const startDate = date
    startDate.setHours(0, 0, 0, 0)
    setStart(startDate)

    const endDate = new Date(startDate)
    endDate.setDate(startDate.getDate() + 1)
    setEnd(endDate)
  }, [selectedDate])

  useEffect(() => {
    console.log("unavailabilities")
    console.log(unavailabilities)
  }, [unavailabilities])

  useEffect(() => {
    console.log("isLoading", isLoading)
  }, [isLoading])


  useEffect(() => {
    
    if (storeSlug) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/shop/${storeSlug}`, {})
        .then(({data}) => {
          setStore(data)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    else {
      setIsLoading(false)
    }
  }, [storeSlug])

  useEffect(() => {
    if (storeSlug && start && end) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/unavailabilities/shop/${storeSlug}`, {
          params: {
            from: start.toISOString(),
            to: end.toISOString(),
          },
        })
        .then(({data}) => {
          setUnavailabilities(data)
        })
    }
  }, [start, end])

  return (
  <>
    <BasicPage>
      <div className={className} style={{display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center",  marginTop: 40, marginBottom: 40 }}>

        <div className="wrapOnSmallScreen" style={{ display: "flex", justifyContent: "space-between", flexWrap: "no-wrap", alignItems: "flex-start", width: "100%"}}>
          <div style={{ marginBottom: 30, padding: 10, border: "2px solid #3493EB", borderRadius: 10, marginLeft: 10, marginRight: 10, flexGrow: 2, flexShrink: 1, maxHeight: 600, maxWidth: 300 }}>
            <p>{store?.name}</p>
            <p style={{ maxHeight: 228, overflow: "scroll" }}>{store?.description}</p>
            <a href={`mailto:${store?.email}`} style={{ color: "#3493EB", cursor: "pointer" }}>{store?.email} <MailOutlined style={{ marginLeft: 15 }} /></a>
            {store?.phone && <p>{store.phone}</p>}
          </div>

          <div className="disponibilitiesContainer" style={{ marginBottom: 30 }}>
            <h3 style={{ fontWeight: "bold", textAlign: "center" }}>{t('AVAILABILITY')}</h3>
            <div className="disponibilitiesContentContainer wrapOnSmallScreen">
              <div style={{ width: 280, height: 300, marginRight: 20 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar 
                    value={selectedDate} 
                    onChange={(newValue) => {
                      console.log("setSelectedDate", newValue)
                      setSelectedDate(newValue)
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap", flexShrink: 5, justifyContent: "center", alignItems: "center", maxWidth: 550 }}>
                {unavailabilities && getTimeSlots(new Date(selectedDate), start, end, unavailabilities).map(
                  ({ timeSlot, selected, unavailabilityStyle }) => {
                    return (
                      <TimeSlot key={timeSlot} {...{ timeSlot, selected, unavailabilityStyle }} />
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>

        <div style={{ padding: 10, width: "100%", marginLeft: 10, marginRight: 10 }}>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>{t('PRODUCTS_AND_SERVICES')}</h3>
          <div style={{ display: "flex" }}>
            {store?.products?.map((product, index) => {
              return <div key={index} style={{ margin: 10, padding: 10, border: "2px solid #3493EB", borderRadius: 20, width: 200 }}>
                  <p><b>{product?.name}</b></p>
                  {product?.sku && <p>SKU : <b>{product.sku}</b></p>}
                  {product?.description && <p>{t('DESCRIPTION')} : <b>{product.description}</b></p>}
                  {product?.price && <p>{t('PRICE')} : <b>{product.price} {store.currencyCode}</b></p>}
                  <p>{t('VAT')} : <b>{product?.vat || 0} %</b></p>
                  <p>{t('VAT_PRICE')} : <b>{product?.vatPrice || product?.price} {store.currencyCode}</b></p>
              </div>
            })}
          </div>
        </div>
      </div>
    </BasicPage>
  </>
)};

export default styled(Store)`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 80px;

  .logo {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 45px;
    opacity: 0.66;
    transition: opacity 0.3s ease-in;

    &:hover {
      opacity: 1;
    }
  }

  .disponibilitiesContainer { padding: 10px; border: 2px solid #3493EB; border-radius: 10px; margin-left: 10px; margin-right: 10px; flex-grow: 3; flex-shrink: 1; justify-content: center; align-items: center }

  .disponibilitiesContentContainer { display: flex; justify-content: center }

  @media (max-width: 680px) {
    padding: 0 25px;
    .container {
      h1 {
        font-size: 3em;
        line-height: 1.1em;
      }

      p {
        font-size: 1.1em;
      }
    }

    .wrapOnMobile { flex-wrap: wrap }
  }

  @media (max-width: 1000px) {
    .wrapOnSmallScreen { flex-wrap: wrap }
  }
`;

function getTimeSlots(date, start, end, unavailabilities) {
  const timeSlots = [];
  let current = new Date(date)
  current.setMinutes(current.getMinutes() > 0 && current.getMinutes() < 30 ? 30 : 0)
  const endDate = new Date(current);
  endDate.setDate(endDate.getDate() + 1);
  const unavailabilitiesStack = [...unavailabilities];

  while (current < endDate) {
    const timeSlot = current;
    timeSlots.push({
      timeSlot,
      selected: start ? isTimeSlotInRange(timeSlot, start, end) : false,
      unavailabilityStyle: getUnavailabilityStyle(
        timeSlot,
        unavailabilitiesStack
      ),
    });
    current = new Date(current.getTime() + TIME_SLOT_DURATION * 60000)
  }
  return timeSlots;
}

function isTimeSlotInRange(timeSlot, start, end) {
  if (start && end) {
    if (timeSlot >= start && timeSlot <= end) {
      return true;
    }
  } else if (timeSlot.isSame(start)) {
    return true;
  }
  return false;
}

function getUnavailabilityStyle(timeSlot, unavailabilitiesStack) {
  if (unavailabilitiesStack?.length) {
    const { start, end } = unavailabilitiesStack[0];
    const timeSlotTs = timeSlot.getTime();
    const startTs = new Date(start).getTime();
    const endTs = new Date(end).getTime();
    //console.log(timeSlotTs + TIME_SLOT_DURATION * 60000, "<=", endTs)
    const endOfTimeSlot = timeSlotTs + TIME_SLOT_DURATION * 60000
    if (endOfTimeSlot >= endTs) unavailabilitiesStack.shift();
    if (endOfTimeSlot <= endTs) {
      if (timeSlotTs >= startTs) {
        return {
          color: colors.RED,
          borderColor: colors.RED,
          borderTopWidth: 1,
          borderLeftWidth: 1,
          borderRightWidth: 0,
          borderBottomWidth: 0,
        };
      }
    } 
  }
  return null;
}

const TimeSlot = ({
  timeSlot,
  unavailabilityStyle,
}) => {

  return (
    <div
      style={{
          display: "flex",
          borderWidth: 1,
          padding: "0px 5px",
          borderRadius: 10,
          alignItems: "center",
          justifyContent: "space-evenly",
          margin: "5px 10px",
          borderStyle: "solid",
          borderColor: "rgba(255, 255, 255, 0.12)",
          ...unavailabilityStyle
      }}
    >
      <p
        style={{
            fontSize: 14,
            fontWeight: "bold",
            margin: 0,
            color: colors.GRAY,
          }
      }>
        {dayjs(timeSlot).format('HH:mm')}
      </p>
    </div>
  );
};