import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import AppointmentDetails from './AppointmentDetails';
import AddToMyCalendar from './AddToMyCalendar';
import CancelAppointment from './CancelAppointment';

const AppointmentModal = ({ appointment, onCancel, onCancelAppointment }) => {
  return (
    <Modal
      title={appointment?.summary}
      forceRender={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={!!appointment}
      onCancel={onCancel}
      footer={null}
    >
      {!!appointment && (
        <React.Fragment>
          <AppointmentDetails appointment={appointment} />
          <AddToMyCalendar appointment={appointment} style={{ marginTop: 20, width: '100%', backgroundColor: "#3493EB", borderColor: "#3493EB", color: "#FFF" }} />
          <CancelAppointment
            appointment={appointment}
            style={{ marginTop: 10, width: '100%' }}
            onCancelAppointment={onCancelAppointment}
          />
        </React.Fragment>
      )}
    </Modal>
  );
};

AppointmentModal.propTypes = {
  appointment: PropTypes.object,
  onCancel: PropTypes.func,
  onCancelAppointment: PropTypes.func,
};

export default AppointmentModal;
