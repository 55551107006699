import React from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AddToMyCalendar = ({ appointment, style }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const onClick = () => {
    const content =
      'BEGIN:VCALENDAR\n' +
      'VERSION:2.0\n' +
      'METHOD:PUBLISH\n' +
      'PRODID:-//ComAgenda//EN\n' +
      'BEGIN:VEVENT\n' +
      'UID:' +
      appointment?._id +
      '\n' +
      'DTSTAMP:' +
      dayjs(appointment?.createdAt).format('YYYYMMDDTHHmmss') +
      '\n' +
      'DTSTART;VALUE=DATE:' +
      dayjs(appointment?.start).format('YYYYMMDDTHHmmss') +
      '\n' +
      'DTEND;VALUE=DATE:' +
      dayjs(appointment?.end).format('YYYYMMDDTHHmmss') +
      '\n' +
      'SUMMARY:' +
      appointment?.summary +
      '\n' +
      'DESCRIPTION:' +
      appointment?.description +
      '\n' +
      'END:VEVENT\n' +
      'END:VCALENDAR';

    const data = new Blob([content], { type: 'text/calendar' });
    window.open(window.URL.createObjectURL(data));
  };

  return (
    <Button style={style} type="primary" onClick={onClick}>
      {t('ADD_TO_MY_CALENDAR')}
    </Button>
  );
};

export default AddToMyCalendar;

AddToMyCalendar.propTypes = {
  appointment: PropTypes.object.isRequired,
};
