import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { message, Spin, Result } from 'antd';
import dayjs from 'dayjs';
import { MailInboxCheckmark24Regular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const VerifyEmail = ({ className }) => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const { token } = useParams();
  const [emailIsVerified, setEmailIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    setEmailIsVerified(false);
    setIsLoading(true);
    const cancelTokenSource = axios.CancelToken.source();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/verify-email`,
        { token },
        {
          cancelToken: cancelTokenSource.token,
        },
      )
      .then(() => {
        setEmailIsVerified(true);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          message.error('An error occurred');
        }
        if (mounted) {
          setIsLoading(false);
        }
      });

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, [token]);

  return (
    <div className={className}>
      <Spin spinning={isLoading}>
        {!!emailIsVerified && (
          <React.Fragment>
            <Result icon={<MailInboxCheckmark24Regular />} title={t('VERIFIED_EMAIL_MSG')} />
          </React.Fragment>
        )}
      </Spin>
    </div>
  );
};

export default styled(VerifyEmail)`
  .ant-card {
    width: 450px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin: 25px auto;
  }
  .ant-result {
    margin: 20px;
    border-radius: 8px;

    .ant-result-title {
      font-weight: bold;
      line-height: 1.5em;
    }

    .ant-result-icon {
      margin-bottom: 10px;
      svg {
        width: 64px;
        height: 64px;
        color: #72be85;
      }
    }
  }
`;
