import { Route, Switch } from 'react-router';
import './App.less';
import PrivateRoute from './components/PrivateRoute';
import Private from './pages/private/index';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Approve from './pages/Approve';
import Cancel from './pages/Cancel';
import Landing from './pages/Landing';
import Error404 from './components/Error404';
import VerifyEmail from './pages/VerifyEmail';
import ChangePassword from './pages/ChangePassword';
import ForgottenPassword from './pages/ForgottenPassword';
import { Privacy } from './pages/Privacy';
import Support from './pages/Support';
import Store from './pages/Store';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Stores from './pages/Stores';
import { MainDataProvider } from './providers/MainDataProvider';

require('dayjs/locale/fr');
require('dayjs/locale/nl');

function App() {
  const {
    i18n: { language },
  } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    dayjs.locale(language.toLowerCase());
  }, [language]);

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <MainDataProvider>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <PrivateRoute path="/app" component={Private}></PrivateRoute>
          <Route exact path="/verify-email/:token" component={VerifyEmail} />
          <Route exact path="/approve/:token" component={Approve} />
          <Route exact path="/cancel/:token" component={Cancel} />
          <Route exact path="/change-password/:token" component={ChangePassword} />
          <Route exact path="/forgotten-password" component={ForgottenPassword} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/support" component={Support} />
          <Route exact path="/shops" component={Stores} />
          <Route exact path="/shops/:storeSlug" component={Store} />
          <Route component={() => <Error404 />} />
        </Switch>
      </MainDataProvider>
    </ThemeProvider>
  );
}

export default App;
