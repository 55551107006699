import React, { useState } from 'react';
import styled from 'styled-components';
import LoginModal from '../components/LoginModal';
import BasicPage from '../components/BasicPage';

const Support = ({ className }) => {
  const [showModalLogin, setShowModalLogin] = useState(false)

  return (
  <>
    <BasicPage>
      <div className={className} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <a 
          href='mailto:contact@comagenda.com'
          style={{ padding: 10, fontSize: 22, color: "#3493EB", cursor: "pointer" }} 
        >
          contact@ComAgenda.com
        </a>
      </div>
    </BasicPage>
    <LoginModal visible={showModalLogin} onCancel={() => setShowModalLogin(false)} />
  </>
)};

export default styled(Support)`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;

  @media (max-width: 680px) {
    padding: 25px;
    .container {
      h1 {
        font-size: 3em;
        line-height: 1.1em;
      }

      p {
        font-size: 1.1em;
      }
    }
  }
`;
