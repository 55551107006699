import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";

const MainDataContext = createContext();

export const MainDataProvider = ({ children }) => {
  const [myShop, setMyShop] = useState();
  const [isConnected, setIsConnected] = useState(false)

  useEffect(() => {
    const token = Cookies.get('auth')
    whoami(token)
  }, []);

  const whoami = (token) => {
    axios
    .get(`${process.env.REACT_APP_API_URL}/whoami`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(() => {
      setIsConnected(true)

      axios
      .get(`${process.env.REACT_APP_API_URL}/shop`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        setMyShop(data)
      })
    })
    .catch(() => {
      Cookies.remove('auth')
    })
  }

  const setToken = (accessToken, expirationDate) => {
    Cookies.set("auth", accessToken, { expires: expirationDate })
    whoami(accessToken)
  }

  return (
    <MainDataContext.Provider
      value={{
        myShop, setMyShop,
        isConnected,
        setToken
      }}
    >
      {children}
    </MainDataContext.Provider>
  );
};

export const useMainData = () => useContext(MainDataContext);
