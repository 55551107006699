import axios from 'axios';
import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const ChangePassword = () => {
  const { t } = useTranslation(undefined, { useSuspense: true });
  const { token } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = (values) => {
    setIsSaving(true);
    const { password } = values;
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/change-password`,
        {
          password,
        },
        { headers: { authorization: `Bearer ${token}` } },
      )
      .then(() => {
        setIsSaving(false);
        history.push('/');
      })
      .catch((error) => {
        setIsSaving(false);
        message.error('An error occurred');
        console.error(error);
      });
  };

  return (
    <div className="fullscreen-form">
      <Form form={form} onFinish={onFinish} size="large" layout="vertical" requiredMark={false}>
        <Form.Item label={t('PASSWORD')} name="password" rules={[{ required: true }]}>
          <Input type="password" prefix="" placeholder={t('PASSWORD')} />
        </Form.Item>
        <Form.Item
          label={t('CONFIRM_PASSWORD')}
          name="confirmPassword"
          rules={[
            {
              required: true,
              validator: (_rule, value) => {
                const password = form.getFieldValue('password');
                if (password === value) {
                  return Promise.resolve(value);
                } else {
                  return Promise.reject('error');
                }
              },
            },
          ]}
        >
          <Input type="password" prefix="" placeholder={t('CONFIRM_PASSWORD')} />
        </Form.Item>
        <Button loading={isSaving} size="large" type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: "#3493EB", borderColor: "#3493EB", color: "#FFF" }}>
          {t('CHANGE_PASSWORD')}
        </Button>
      </Form>
    </div>
  );
};

export default ChangePassword;
